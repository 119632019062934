// Guards //
import guards from './guards';

// Layout //
const Layout = () => import('../layouts/SuperAdmin/SuperAdminDashboardLayout');

// Default //
const Home = () => import('../views/SuperAdmin/AdminHome');
const About = () => import('../views/About');

// custom //
const User = () => import('../views/SuperAdmin/User/Users');
const Categories = () => import('../views/SuperAdmin/Category/Categories');
const Catalog = () => import('../views/SuperAdmin/Catalog/Catalog');
const Shops = () => import('../views/SuperAdmin/Shops/Shop');
const ShopDetails = () => import('../views/SuperAdmin/Shops/ShopDetails');
const UserDetails = () => import('../views/SuperAdmin/User/UserDetails');
const OrderDetails = () => import('../views/SuperAdmin/Shops/Orders/OrderDetails');

export default {
    path: '/admin/',
    name: 'SuperAdminDashboardLayout',
    redirect: '/admin/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '/admin/',
            name: 'Home',
            component: Home
        },
        {
            path: '/admin/user/',
            name: 'User',
            component: User
        },
        {
            path: '/admin/user/:id/details/',
            name: 'UserDetails',
            component: UserDetails
        },
        {
            path: '/admin/categories/',
            name: 'Categories',
            component: Categories
        },
        {
            path: '/admin/catalog/',
            name: 'Catalog',
            component: Catalog
        },
        {
            path: '/admin/shops/',
            name: 'Shops',
            component: Shops
        },
        {
            path: '/admin/shops/:id/details/',
            name: 'ShopDetails',
            component: ShopDetails
        },
        {
            path: '/admin/order/view/:id/details/',
            name: 'OrderDetails',
            component: OrderDetails
        },
        {
            path: '/admin/order/:id/details/',
            name: 'ShopDetails',
            component: ShopDetails
        },
        {
            path: '/about/',
            name: 'About',
            component: About
        },
        {
            path: '*',
            name: '404',
            component: () => import('../views/auth/404.vue')
        }
    ]
};
