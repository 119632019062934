const AuthLayout = () => import('../layouts/AuthLayout');
const Login = () => import('../views/auth/Login');
const changePassword = () => import('../views/auth/changePassword');
// PageNotFound//
const PageNotFound = () => import('../views/auth/404');
const newUser = () => import('../views/auth/NewUserRegistration');

export default {
    path: '/login/',
    name: 'GuestPages',
    redirect: '/login/',
    component: AuthLayout,
    children: [
        {
            path: '/login/',
            name: 'Login',
            component: Login,
            props: true
        },
        {
            path: '/change-password/',
            name: 'changePassword',
            component: changePassword
        },
        {
            path: '/404/',
            name: 'PageNotFound',
            component: PageNotFound
        },
        {
            path: '/register/',
            name: 'newUser',
            component: newUser
        },
        {
            path: '*',
            name: '404',
            component: PageNotFound
        }
    ]
};
