import Vue from 'vue';
import VueRouter from 'vue-router';
import SuperAdminRoutes from './superAdmin-layout';
import ShopAdminRoutes from './shopAdmin-layout';
import customerRoutes from './customer-layout';
import AuthRoutes from './auth';
import NProgress from 'nprogress';

Vue.use(VueRouter);

const routes = [
    AuthRoutes,
    SuperAdminRoutes,
    ShopAdminRoutes,
    customerRoutes
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeResolve((to, from, next) => {
    if (to.name) {
        NProgress.start();
    }
    next();
});

router.afterEach(() => {
    NProgress.done();
});

export default router;
