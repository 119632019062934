const prod = process.env.NODE_ENV === 'production';

let baseUrl;
if (prod) {
    baseUrl = '/api/';
} else {
    baseUrl = '/api/';
}

const categories = {
    create: baseUrl + 'categories/create/',
    update: baseUrl + 'categories/update/',
    delete: baseUrl + 'categories/delete/',
    list: baseUrl + 'categories/all/',
    dropdownlist: baseUrl + 'categories/dropdown-list/'
};
const auth = {
    login: baseUrl + 'users/login/',
    logout: baseUrl + 'users/logout/',
    status: baseUrl + 'users/login-status/',
    ChangePassword: baseUrl + 'users/set-password/'
};

const cart = {
    cartdetails: baseUrl + 'cart/cart-details/',
    removeCart: baseUrl + 'cart/remove/',
    addCard: baseUrl + 'cart/add-to-Cart/'
};
const userRoles = {
    dropdownlist: baseUrl + 'user-roles/drop-down-list/'
};
const users = {
    create: baseUrl + 'users/create/',
    update: baseUrl + 'users/update/',
    delete: baseUrl + 'users/delete/',
    list: baseUrl + 'users/all/'
};
const userAddress = {
    create: baseUrl + 'user-address/create/',
    update: baseUrl + 'user-address/update/',
    delete: baseUrl + 'user-address/delete/',
    list: baseUrl + 'user-address/all/',
    details: baseUrl + 'user-address/user-details/'
};
const product = {
    create: baseUrl + 'product/create/',
    update: baseUrl + 'product/update/',
    delete: baseUrl + 'product/delete/',
    list: baseUrl + 'product/all/'
};
const productImages = {
    create: baseUrl + 'product/add-product-image/',
    delete: baseUrl + 'product/remove-product-image/',
    list: baseUrl + 'product/product-image-list/'
};
const shop = {
    create: baseUrl + 'shop/create/',
    update: baseUrl + 'shop/update/',
    delete: baseUrl + 'shop/delete/',
    list: baseUrl + 'shop/all/',
    get: baseUrl + 'shop/get-by-id/',
    EnableOrDisable: baseUrl + 'shop/enable-disable/',
    ShopListForUsers: baseUrl + 'shop/shop-list-users/'
};
const shopProduct = {
    create: baseUrl + 'shop-product/create/',
    update: baseUrl + 'shop-product/edit-shop-product/',
    delete: baseUrl + 'shop-product/delete/',
    list: baseUrl + 'shop-product/shop-details/',
    productlist: baseUrl + 'shop-product/product-filter-list/',
    EnableOrDisable: baseUrl + 'shop-product/disable/',
    shopproductlist: baseUrl + 'shop-product/shop-product-list/',
    shopdetailsadmin: baseUrl + 'shop-product/shop-details-admin/'
};
const shopOrder = {
    addtoorder: baseUrl + 'orders/add-to-order-details/',
    addtoorder1: baseUrl + 'orders/add-to-order-items/',
    update: baseUrl + 'orders/update-status/',
    delete: baseUrl + '',
    list: baseUrl + 'orders/list-order/',
    orderdetails: baseUrl + 'orders/order-details/',
    placeorder: baseUrl + 'orders/place-order/',
    RemoveItem: baseUrl + 'orders/remove-cart/'
};

const NewAPIs = {
    ChangeStatus: baseUrl + 'v2/product/enable-disable/',
    ProdDelete: baseUrl + 'v2/product/delete/'

};
export default {
    categories,
    userRoles,
    users,
    userAddress,
    product,
    productImages,
    shop,
    shopProduct,
    shopOrder,
    auth,
    cart,
    NewAPIs
};
