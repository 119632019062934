<template>
    <div>
        <b-card-body>
            <LoadingAnimation color="#000000"></LoadingAnimation>
            Loading data from the server.
        </b-card-body>
    </div>
</template>

<script>
import LoadingAnimation from '@components/Loading';

export default {
    name: 'loading-card',
    components: {
        LoadingAnimation
    }
};
</script>
