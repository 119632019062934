export default {
    state: {
        publicKey: null
    },

    getters: {
        publicKey: state => state.publicKey
    },

    mutations: {
        setPublicKey (state, payload) {
            state.publicKey = payload;
        }
    },

    actions: {
        setPublicKey ({ commit }, payload) {
            commit('setPublicKey', payload);
        }
    }
};
