import BaseCheckbox from 'v-components/src/forms-components/BaseCheckbox';
import BaseRadio from 'v-components/src/forms-components/BaseRadio';
import DropzoneFileUpload from 'v-components/src/forms-components/DropzoneFileUpload';
import FileInput from 'v-components/src/forms-components/FileInput';
import HtmlEditor from 'v-components/src/forms-components/HtmlEditor';
import IconCheckbox from 'v-components/src/forms-components/IconCheckbox';
import TagsInput from 'v-components/src/forms-components/TagsInput';

import BaseForm from 'v-components/src/forms/BaseForm';
import config from 'v-components/src/Config';

import ValidatedInput from 'v-components/src/validated-form-fields/ValidatedInput';
import ValidatedCheckbox from 'v-components/src/validated-form-fields/ValidatedCheckbox';
import ValidatedSelect from 'v-components/src/validated-form-fields/ValidatedSelect';
import ValidatedTextArea from 'v-components/src/validated-form-fields/ValidatedTextArea';
import ValidatedVueSelect from 'v-components/src/validated-form-fields/ValidatedVueSelect';
import ValidatedDatePicker from 'v-components/src/validated-form-fields/ValidatedDatePicker';
import ValidatedVCalendar from 'v-components/src/validated-form-fields/ValidatedVCalendar';

import Notifications from 'v-components/src/notification';
import MessageBox from 'v-components/src/message-box';

import Actions from 'v-components/src/tables/Actions';
import BootstrapTable from 'v-components/src/tables/BootstrapTable';
import CardDataList from 'v-components/src/tables/CardDataList';
import DataList from 'v-components/src/tables/DataList';
import SimpleVueTable from 'v-components/src/tables/SimpleVueTable';
import SimpleTable from 'v-components/src/tables/SimpleTable';
import VueTable from 'v-components/src/tables/VueTable';
import VueTablePaginationBootstrap from 'v-components/src/tables/VueTablePaginationBootstrap';

import SuccessIcon from 'v-components/src/svg-icons/SuccessIcon';
import ErrorIcon from 'v-components/src/svg-icons/ErrorIcon';
import LoadingAnimation from 'v-components/src/svg-icons/LoadingAnimation';

import LoadingButton from 'v-components/src/buttons/LoadingButton';
// # Custom
import LoadingCard from '@/components/LoadingCard';

import * as validator from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import { messages } from 'vee-validate/dist/locale/en.json';

function installForms (Vue) {
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(BaseRadio.name, BaseRadio);
    Vue.component(DropzoneFileUpload.name, DropzoneFileUpload);
    Vue.component(FileInput.name, FileInput);
    Vue.component(HtmlEditor.name, HtmlEditor);
    Vue.component(IconCheckbox.name, IconCheckbox);
    Vue.component(TagsInput.name, TagsInput);

    Vue.component(ValidatedInput.name, ValidatedInput);
    Vue.component(ValidatedCheckbox.name, ValidatedCheckbox);
    Vue.component(ValidatedSelect.name, ValidatedSelect);
    Vue.component(ValidatedVueSelect.name, ValidatedVueSelect);
    Vue.component(ValidatedTextArea.name, ValidatedTextArea);
    Vue.component(ValidatedDatePicker.name, ValidatedDatePicker);
    Vue.component(ValidatedVCalendar.name, ValidatedVCalendar);

    Vue.component(BaseForm.name, BaseForm);
    config.addVariation('form', '', {
        animation: 'scale',
        confirmOnSubmit: true,
        confirmMessage: 'Are you sure ?',
        confirmVariant: 'success',
        confirmTitle: 'Confirm Submitting The Request',
        confirmYesText: 'Yes, Please Proceed',
        confirmNoText: 'No, I am afraid',

        hideFormOnOptionsLoadingFailed: true,
        hideFormOnOptionsLoading: false,
        hideFormOnSave: true,

        showConfirmPageBeforeSubmit: false,
        showErrorPageOnFailed: false,

        showNotificationOnFailed: true,
        failedNotificationText: 'Some errors occured while communicating with server. Please try again later.'
    });
}

function installValidator (Vue) {
    Vue.component('validation-provider', validator.ValidationProvider);
    Vue.component('validation-observer', validator.ValidationObserver);

    Object.keys(rules).forEach(rule => {
        validator.extend(rule, {
            ...rules[rule], // copies rule configuration
            message: messages[rule] // assign message
        });
    });

    validator.configure({
        classes: {
            valid: 'is-valid',
            invalid: 'is-invalid',
            dirty: ['is-dirty', 'is-dirty'] // multiple classes per flag!
        }
    });
}

function installTables (Vue) {
    Vue.component(Actions.name, Actions);
    Vue.component(BootstrapTable.name, BootstrapTable);
    Vue.component(CardDataList.name, CardDataList);
    Vue.component(DataList.name, DataList);
    Vue.component(SimpleTable.name, SimpleTable);
    Vue.component(SimpleVueTable.name, SimpleVueTable);
    Vue.component(VueTable.name, VueTable);
    Vue.component(VueTablePaginationBootstrap.name, VueTablePaginationBootstrap);
}

function installIcons (Vue) {
    Vue.component(ErrorIcon.name, ErrorIcon);
    Vue.component(SuccessIcon.name, SuccessIcon);
    Vue.component(LoadingAnimation.name, LoadingAnimation);
    // Custom
    Vue.component(LoadingCard.name, LoadingCard);
}

function installButtons (Vue) {
    Vue.component(LoadingButton.name, LoadingButton);
}

export default {
    install: function (Vue) {
        Vue.use(Notifications);
        Vue.use(MessageBox);

        installForms(Vue);
        installValidator(Vue);
        installTables(Vue);
        installIcons(Vue);
        installButtons(Vue);
    }
};
