// Guards //
import guards from './guards';

// Layout //
const Layout = () => import('../layouts/Consumer/DashboardLayout');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        {
            path: '*',
            name: '404',
            component: () => import('../views/auth/404.vue')
        }
    ]
};
